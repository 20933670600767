.Footer {
    border-top: 1px solid var(--light-gray);
    background-color: var(--dark-gray);

    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 200px;

    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Footer .PageLinks {
    height: calc(100% - 60px);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.Footer .PageLinks .PageLink{
    padding: 1px 8px;
    font-size: 14px;
    color: var(--light-gray-2);
    font-weight: bold;
    text-decoration: none;
}

.Footer .Branding {
    scroll-padding-right: 20px;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
    height: 100%;
}

.Footer .Branding .Socials {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.Footer .Branding .Socials .Social {
    display:flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    padding: 12px;
    height: 33%;

    color: var(--light-gray-2);
    font-weight: bold;
    text-decoration: none;
}

.Footer .Branding .Socials .Social img {
    height: 24px;
    margin-left: 10px;
    filter: contrast(0) brightness(0.7);
}

.Footer .Branding .Logo {
    height: 80%;
    margin-right: 10px;  
}

@media screen and (max-width: 700px) {
    .Footer .Branding .Socials .Social .AccountName {
        display: none;
    }
}