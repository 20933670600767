.MenuContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 70vw;
    height: 73vw;
    background-color: var(--black);
    color: var(--white);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.MenuContainer a {
    color: inherit;
    margin: calc(9vw - 56px);
    padding: 4vw;
    font-weight: bold;
    font-size: 9vw;
    text-decoration: none;
}

.Hamburger {
    position: absolute;
    padding: 10px;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;

    border-radius: 10px;
    color: var(--white);
    background: var(--black);
    border: none;
}

.MenuContainer {
  z-index: 100;
  overflow: hidden;
}

.MenuTransition-enter {
  z-index: 100;
  width: 60px;
  height: 60px;
  color: transparent;
}
.MenuTransition-enter-active {
  z-index: 100;
  color: var(--white);
  width: 70vw;
  height: 73vw;
  transition: all 0.6s;
  transition-timing-function: ease-in-out;
}
.MenuTransition-exit {
  z-index: 100;
  width: 70vw;
  height: 73vw;
  color: var(--white);
}
.MenuTransition-exit-active {
  z-index: 100;
  width: 60px;
  height: 60px;
  color: transparent;
  transition: all 0.6s;
  transition-timing-function: ease-in-out;
}