.About {
    width : calc(100% - 30px);
    padding: 15px;
}

.About .Title {
    display:block;
    font-size: calc(5vw + 30px);
    font-weight: bold;
}

.About .Description {
    text-align: left;
    display:block;
    padding: 0px 2vw;
}