.GamePlayer {
    user-select: none;

    padding: 20px;
    width: fit-content;
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.GamePlayer .PlayerArea {
    position:relative;
    width: fit-content;
}

.GamePlayer canvas {
    max-height: calc(50vh);
    max-width: 100%;
    width: auto;
    height: auto;
    touch-action: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.GamePlayer input {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    font-family: 'Poppin', sans-serif;
    font-weight: bold;
    text-align: center;
}

.GamePlayer input:focus {
    outline: none
}

.GamePlayer .InfoArea .TitleArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width:100%;
    background-color: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.GamePlayer .InfoArea .TitleArea .Title {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: calc(1vw + 15px);
    color: var(--black)
}

.GamePlayer .InfoArea {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: var(--black)
}

.GamePlayer .InfoArea .Fullscreen {
    margin-left: 20px;
    width: calc(1vw + 46px);
    height: calc(1vw + 46px);
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    border: none;
    background-color: var(--white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.GamePlayer .InfoArea .Fullscreen:hover {
    cursor: pointer;
}

.GamePlayer .InfoArea .Fullscreen img{
    height: 80%;
    filter: brightness(0);
}

.GamePlayer .FULL {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-gray);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
}

.GamePlayer .FULL canvas {
    width: auto;
    max-height: calc(100vh);
    max-width: calc(100%);
    height: auto;
    touch-action: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 0;
}


.GamePlayer .FULL .Fullscreen {
    position: fixed;
    bottom: 20px;
    right: 20px;

    width: 56px;
    height: 56px;
    border: none;
    background-color: rgba(50,50,50,0.5);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;

    display:flex;
    justify-content: center;
    align-items: center;
}

.GamePlayer .FULL .Fullscreen img{
    height: 80%;
    filter: brightness(0);
}