.GameSelector {
    font-size: 24px;
    border: none;

    width: 100%;
    margin: 5px;
    padding: 5px;
    background-color: var(--light-gray-2);
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    color: var(--white);
    text-align: center;

    font-weight: bold;
}

.GameSelector:hover {
    cursor: pointer;
}