.Games {
    display: none;
    background-color: var(--light-gray);

}

.Games .GamePlayerArea {
    position: relative;
    display: none;
}

.Games .GamePlayerArea .Warning {
    font-weight: bold;
    position:absolute;
    z-index: 100;
    top: 0;
    content: attr(warning);
    background-color: var(--yellow);
    padding: 10px;
    border-radius: 5px;
}

.Games .GamePlayerArea button {
   border: none;
   background-color: black;
   margin-left: 5px;
   color:white;
   font-weight: bold;
   font-size: 16px;
   border-radius: 5px;
   padding: 10px;
}

.Games .GamePlayerArea button:hover {
    cursor: pointer;
    background-color: var(--dark-gray)
 }


.Games .GameLibraryArea {
    display: none;
}

@media screen and (min-width: 860px) {
    .Games {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        height: fit-content
    }

    .Games .GameLibraryArea {
        display:flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: fit-content;
    }

    .Games .GamePlayerArea {
        display:flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 300px);
        height: fit-content;
    }
}

@media screen and (max-width: 860px) {
    .Games {
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .Games .GameLibraryArea {
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height:fit-content;
    }

    .Games .GamePlayerArea {
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height:fit-content;
    }
}