.HeaderBar {
    line-height: 100px;

    width: 100vw;
    height: 100px;
    background-color: white;
    border-bottom: 1px solid var(--light-gray);

    display: flex;
    justify-content: space-between;
}

.HeaderBar .BrandContainer {
    width: 50%;
    height: 100px;
}

.HeaderBar .BrandContainer .Title, .Logo {
    height: 100%;
}

.HeaderBar .Links a {
    color: var(--black);
    margin: calc(10vw - 60px);
    padding: 20px;
    font-weight: bold;
    font-size: 24px;
    text-decoration: none;
}

.HeaderBar .Menu {
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 1000px) {
    .HeaderBar .Title {
        display: none;
    }
}

@media screen and (min-width: 1000px) {
    .HeaderBar .Logo {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .HeaderBar .Links {
        display: none;
    }
}

@media screen and (min-width: 640px) {
    .HeaderBar .Menu {
        display: none;
    }
}