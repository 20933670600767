:root {
  --white: white;
  --black: rgb(30, 30, 30);
  --yellow: #FBB040;
  --blue: #00AEEF;
  --light-gray: rgb(222, 222, 222);
  --light-gray-2: rgb(100, 100, 100);
  --dark-gray: rgb(50,50,50);
}

.Content {
  z-index: 0;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 301px);
  padding-bottom: 200px;
}